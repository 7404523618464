import React from 'react'
import Carousel from './Carousel'

function Portfolio() {

    return (
        <div className="portfolio component">
            <Carousel />

        </div>
    )
}

export default Portfolio