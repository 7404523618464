import React from 'react'

function Extracurriculars() {

    return (
        <div className="extracurriculars component">
            
        </div>
    )
}

export default Extracurriculars